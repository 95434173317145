import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Language, LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-claim-process',
  templateUrl: './claim.process.component.html',
  styleUrls: ['./claim.process.component.scss']
})
export class ClaimProcessComponent implements OnInit {
  public languages;
  public selectedLanguage: Language;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private languageService: LanguageService) {
      this.selectedLanguage = this.languageService.getDefaultLang();
    }

  goTo(): void {
    this.router.navigate(['/ticket-details']);
  }

  public switchLanguage($event): any {
    this.selectedLanguage = this.languageService.switchLanguage($event);
    this.translate.use(this.selectedLanguage.languageCode);
    this.languageService.setLanguageState$($event);
  }

  ngOnInit(): void {
    this.languages = this.languageService.getLanguages();
  }
}
