<div class="ticket-check-main">
  <mat-grid-list cols="12">
    <mat-card class="ticket-check-table logo-container">
      <div class="logo-form">
        <img src="../../../assets/images/logo_login.png" class="logo" />
      </div>
    </mat-card>

    <div class="ticket-check-table">
      <div class="ticket-check-heading">
        <h1>
          {{ "TICKET_CHECK.HEADING" | translate }}
        </h1>
        <h3>
          <span>{{ "TICKET_CHECK.RECEIPT" | translate }}: </span><span>{{ticketNumber}}</span>
        </h3>
      </div>
      <mat-table #table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="ticketNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "TICKET_CHECK.COLUMN_TICKET_NUMBER" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.ticket_id}} </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="prizeDistribution">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "TICKET_CHECK.COLUMN_DISTRIBUTION" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.prize}} </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="prizeAmount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "TICKET_CHECK.COLUMN_PRIZE" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.amount}} </mat-cell>
        </ng-container>
      
        <mat-header-row *matHeaderRowDef="columnHeaders"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnHeaders;"></mat-row>
      </mat-table>
      <div class="ticket-check-heading">
        <mat-paginator #paginator
          [pageSizeOptions]="pageSizeOptions"
          showFirstLastButtons
          aria-label="Select page"
        ></mat-paginator>
    </div>
    </div>
  </mat-grid-list>
</div>
