<div class="thank-you">
    <mat-grid-list cols="12">
        <mat-card class="logo-container">
            <div class="logo-form">
                <img src="../../../assets/images/logo_login.png" class="logo" />
            </div>
        </mat-card>
        <mat-card-content>
            <mat-card class="thank_you_card">
                <img src="../../../assets/images/ThankYou.svg"/>
                <h2>{{'LAST_PAGE.MESSAGE' | translate}}</h2>
                <p>{{'LAST_PAGE.SUB_MESSAGE' | translate}}</p>
            </mat-card>
        </mat-card-content>
    </mat-grid-list>
</div>
