import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Alert } from 'src/app/services/util.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../alert/alert.component';
import { TranslateService } from '@ngx-translate/core';
import { Language, LanguageService } from '../../services/language.service';
import { TicketCheckService, ReceiptTicket } from '../../services/ticket.check.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-ticket-check',
  templateUrl: './ticket.check.component.html',
  styleUrls: ['./ticket.check.component.scss'],
})
export class TicketCheckComponent implements OnInit {
  public languages;
  public selectedLanguage: Language;
  public columnHeaders = ['ticketNumber', 'prizeDistribution', 'prizeAmount'];
  public ticketNumber: number;
  public paginatedTickets:Array<ReceiptTicket>;
  public currentPageIndex = 0;
  public currentPage = 0;
  public pageSizeOptions: number[] = [5, 10, 15];

  public dataSource: MatTableDataSource<ReceiptTicket>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: ActivatedRoute,
    private dialog: MatDialog,
    public translate: TranslateService,
    private languageService: LanguageService,
    private ticketCheckService: TicketCheckService,
  ) {
    this.selectedLanguage = this.languageService.getDefaultLang();
  }

  public switchLanguage($event): any {
    this.selectedLanguage = this.languageService.switchLanguage($event);
    this.translate.use(this.selectedLanguage.languageCode);
    this.languageService.setLanguageState$($event);
  }

  ngOnInit(): void {
    this.languages = this.languageService.getLanguages();
    this.router.params
        .subscribe(
          (params: Params) => {
            this.ticketNumber = params['ticketId'];
            this.checkTicket(this.ticketNumber);
          }
        );
  }

  public openDialog(alert: Alert): any {
    this.dialog.open(AlertComponent, {
      data: alert,
      panelClass: 'custom-alert',
    });
  }

  public checkTicket(ticketId: number): void {
    this.ticketCheckService.checkTicket(ticketId)
    .subscribe(
      (response) => {
        if (!!response) {
          this.ticketNumber = response.receiptId;
          this.paginatedTickets = response.tickets;
          this.paginatedTickets.sort((a, b) => b.amount - a.amount);
          this.dataSource = new MatTableDataSource<ReceiptTicket>(this.paginatedTickets);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      });

  }
}
