<div class="ticket-verification">
  <mat-grid-list cols="12">
    <mat-card class="login-instructions logo-container">
      <div class="logo-form">
        <img src="../../../assets/images/logo_login.png" class="logo" />
      </div>
    </mat-card>

    <div>
      <mat-card class="login-instructions">
        {{ "TC_LOGIN.LOGIN_INSTRUCTION" | translate }}
      </mat-card>
    </div>

    <form [formGroup]="loginForm" (submit)="login()">
      <mat-card class="login-form"> 
        <mat-grid-list cols="12">
          <mat-form-field appearance="outline" class="input-form-size">
            <mat-label>{{ "LOGIN.TICKETID" | translate }}</mat-label>
            <input
              type="text"
              matInput
              minlength="3"
              formControlName="ticketId"
              placeholder="{{ 'LOGIN.TICKET_ID_REQUIRED' | translate }}"
              pattern="^[0-9][^.]*"
              (keypress)="isNumberKey($event)"
            />
            <mat-icon matSuffix>lock</mat-icon>
            <mat-error *ngIf="checkError('ticketId', 'required')"
              >{{ "LOGIN.TICKET_ID_REQUIRED" | translate }}
            </mat-error>
            <mat-error *ngIf="checkError('ticketId', 'pattern')">{{
              "LOGIN.TICKET_ID_INVALID" | translate
            }}</mat-error>
            <mat-error *ngIf="checkError('ticketId', 'minlength')"
              >Ticket should at least be 3 numbers long</mat-error
            >
          </mat-form-field>
        </mat-grid-list>

        <mat-grid-list cols="12">
          <mat-form-field class="input-form-size" appearance="outline">
            <mat-label>{{ "LOGIN.PASSWORD" | translate }}</mat-label>
            <input
              type="password"
              minlength="3"
              maxlength="6"
              matInput
              #password
              formControlName="securityCode"
              pattern="^[A-Za-z0-9]+$"
              placeholder="{{ 'LOGIN.PASSWORD_PLACEHOLDER' | translate }}"
            />
            <mat-icon matSuffix>security</mat-icon>
            <mat-error *ngIf="checkError('securityCode', 'required')">
              {{ "LOGIN.PASSWORD_REQUIRED" | translate }}</mat-error
            >
            <mat-error *ngIf="checkError('securityCode', 'pattern')">{{
              "LOGIN.PASSWORD_INVALID" | translate
            }}</mat-error>
            <mat-error *ngIf="checkError('securityCode', 'minlength')"
              >Security Code should at least be 3 characters long</mat-error
            >
          </mat-form-field>
        </mat-grid-list>
        <mat-grid-list cols="12" class="login">
          <button
            mat-raised-button
            color="primary-btn"
            [disabled]="!loginForm.valid"
          >
            {{ "TC_LOGIN.BUTTON" | translate }}
          </button>
        </mat-grid-list>
      </mat-card>
    </form>
  </mat-grid-list>
</div>
