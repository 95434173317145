import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TicketService } from 'src/app/services/ticket.service';
import { FileConverterService } from 'src/app/services/file-converter.service';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { accountNumberValidator } from '../../validators/accountNumberValidator';
import { AuthService } from 'src/app/services/auth.service';
import { Alert, UtilService } from 'src/app/services/util.service';
import { AlertComponent } from '../alert/alert.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import CountryCode from '../../services/country-codes';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register-ticket',
  templateUrl: './register-ticket.component.html',
  styleUrls: ['./register-ticket.component.scss'],
})
export class RegisterTicketComponent implements OnInit, OnDestroy {
  public submitted = false;
  public registerTicketGroup: FormGroup;
  private TICKET_SCAN_MAX_FILE_SIZE = 5242880;
  private TICKET_SCAN_FILE_EXTENSION_ALLOWED = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/pdf',
  ];
  public image: any;
  public ticketId;
  public ticketSubmit;
  public country: Array<any>;
  public code: string;
  public countryFlag: string;
  private registerCounter: number = 0;
  heading: string;
  headingSubscription: Subscription;
  registerSubscription: Subscription;
  converterSubscription: Subscription;
  ticketInfo: any;

  constructor(
    private formBuilder: FormBuilder,
    private ticketService: TicketService,
    private router: Router,
    private fileConverterService: FileConverterService,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.ticketId = sessionStorage.getItem('ticketId');
    this.registerTicketGroup = this.formBuilder.group({
      id: sessionStorage.getItem('ticketId'),
      customer: this.formBuilder.group({
        //
        name: ['', [Validators.required]],
        cell: ['', [Validators.required]],
        address: [''],
        zip: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
        city: ['', [Validators.required]],
        email: ['', [Validators.email]],
        accountNumber: ['', [Validators.required, accountNumberValidator]],
      }),
      scannedId: ['', [Validators.required]],
    });
    // this.image = null;
    this.registerTicketGroup.markAsTouched();
    this.country = JSON.parse(JSON.stringify(CountryCode()));
    this.countryFlag = '../../../assets/images/country_flags/de.png';
    this.code = '49';
    this.headingSubscription = this.translate
      .get('HEADER', { value: 'TITLE_STAGE_1' })
      .subscribe((res: { TITLE_STAGE_2: string; TITLE_STAGE_1: string }) => {
        this.heading = res.TITLE_STAGE_2;
        //=> 'hello world'
      });
  }

  get registerTicketFormControl(): any {
    return this.registerTicketGroup.controls;
  }
  public ssnTrigger(): boolean {
    this.ticketInfo = JSON.parse(sessionStorage.getItem('ticket'));
    return this.ticketInfo?.prize > this.ticketInfo.minLimit;
  }

  public switchCountry($event): any {
    this.code = $event;
    this.countryFlag = `../../../assets/images/country_flags/${this.country
      .filter((country) => country.dial_code === $event)[0]
      .code.toLowerCase()}.png`;
    this.registerCounter = 0;
  }

  public getCountryFlag(code: string): string {
    return `../../../assets/images/country_flags/${code.toLowerCase()}.png`;
  }

  public openDialog(alert: Alert): any {
    this.dialog.open(AlertComponent, {
      data: alert,
      panelClass: 'custom-alert',
    });
  }

  public onFileChange($event): void {
    const file = $event.target.files[0];
    if ($event.target.files.length > 0) {
      const formData = new FormData();
      const filename = this.registerTicketGroup.get('id').value + '.jpg';
      formData.append('scannedId', file);
      formData.append('ticketId', this.registerTicketGroup.get('id').value);
      formData.append('securityCode', sessionStorage.getItem('pwd'));
      this.converterSubscription = this.fileConverterService.convert(formData).subscribe(
        (response) => {
          const reader = new FileReader();
          reader.readAsDataURL(response);
          reader.onloadend = () => {
            this.image = reader.result;
            const blob = this.b64toBlob(reader.result, filename);
            this.registerTicketGroup.get('scannedId').setValue(blob);
          };
        },
        (err) => {
          this.openDialog(
            UtilService.alertMessage(
              this.translate.instant(
                'REGISTER_TICKET.ERROR.FILE_UPLOAD_ERROR_MESSAGE'
              ),
              0
            )
          );
        }
      );
      this.cd.markForCheck();
    }
    this.ticketScanFileType(
      this.TICKET_SCAN_FILE_EXTENSION_ALLOWED,
      $event.target.files[0].type
    );
    this.ticketScanFileSize(
      $event.target.files[0].size,
      this.TICKET_SCAN_MAX_FILE_SIZE
    );
  }

  private ticketScanFileSize(
    actualfileSize: number,
    acceptedMaxSize: number
  ): any {
    if (actualfileSize > acceptedMaxSize) {
      this.openDialog(
        UtilService.alertMessage(
          this.translate.instant(
            'REGISTER_TICKET.ERROR.FILE_SIZE_ERROR_MESSAGE'
          ),
          0
        )
      );
    }
  }

  private ticketScanFileType(ex: string[], fileType: string): any {
    if (!_.includes(ex, fileType)) {
      this.openDialog(
        UtilService.alertMessage(
          this.translate.instant(
            'REGISTER_TICKET.ERROR.FORMART_TYPE_ERROR_MESSAGE'
          ),
          0
        )
      );
    }
  }

  trailingZeroMethod(value): string {
    let temp = value.toString();
    let firstChar = temp.charAt(0);
    if (firstChar !== '0') {
      temp = '0' + temp;
    }
    return temp;
  }

  public registerTicket(): void {
    if (this.registerCounter === 0) {
      this.registerTicketGroup.patchValue({
        customer: {
          ...this.registerTicketGroup.value.customer,
          cell: parseInt(
            this.code + this.registerTicketGroup.value.customer.cell
          ),
        },
      });
      this.registerCounter = this.registerCounter + 1;
    }

    const formData = new FormData();
    formData.append(
      'scannedId',
      this.registerTicketGroup.get('scannedId').value
    );
    formData.append('ticket', JSON.stringify(this.registerTicketGroup.value));
    formData.append('ticketId', this.registerTicketGroup.get('id').value);
    formData.append('securityCode', sessionStorage.getItem('pwd'));
    this.submitted = true;
    this.registerSubscription = this.ticketService.registerTicket(formData).subscribe(
      (response) => {
        this.router.navigate(['/confirmation'], { state: { ticketId: this.ticketId, securityCode: sessionStorage.getItem('pwd') } });
      },
      (err) => {
        this.openDialog(
          UtilService.alertMessage(
            this.translate.instant(
              'REGISTER_TICKET.ERROR.REGISTER_ERROR_MESSAGE'
            ),
            0
          )
        );
      }
    );
  }

  public b64toBlob(dataURI: any, filename: string): any {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'image/jpeg' });
    return new File([blob], filename, { type: 'image/jpeg' });
  }

  public isNumberKey(event: KeyboardEvent): boolean {
    return this.utilService.isNumberKey(event);
  }

  public isLegalCharacter(event: KeyboardEvent): boolean {
    return this.utilService.isLegalCharacter(event);
  }

  ngOnDestroy(): void {
    this.authService.logout();
    if (this.headingSubscription) this.headingSubscription.unsubscribe();
    if (this.converterSubscription) this.converterSubscription.unsubscribe();
    if (this.registerSubscription) this.registerSubscription.unsubscribe();
  }
}
