<div class="landing-main">
  <mat-grid-list cols="12">
    <mat-card class="logo-container">
      <div class="logo-form">
        <img src="../../../assets/images/logo_login.png" class="logo" />
      </div>
    </mat-card>

    <div>
      <mat-card class="landing-instructions">
        {{ "LANDING.INSTRUCTION" | translate }}
      </mat-card>
    </div>

    <div class="full-w">
      <mat-card class="landing"> 
        <mat-grid-list cols="12">
          <button mat-raised-button class="primary-btn"
            (click)="showClaimTicket()">
            {{ "LANDING.CLAIM_TICKET_BUTTON" | translate }}
          </button>
        </mat-grid-list>

        <mat-grid-list cols="12">
          <button mat-raised-button class="secondary-btn"
            (click)="checkTicket()">
            {{ "LANDING.CHECK_RESULTS_BUTTON" | translate }}
          </button>
        </mat-grid-list>
        <mat-grid-list cols="12">
          <button mat-raised-button class="view-prize-schedule secondary-btn"
            (click)="showPrizedTicket()">
            {{ "LANDING.VIEW_TICKETS_BUTTON" | translate }}
          </button>
        </mat-grid-list>
      </mat-card>
    </div>

    <div >
      <mat-card class="landing-lang">
        <mat-form-field class="input-form-size" appearance="outline">
          <mat-select
            #langSelect
            (selectionChange)="switchLanguage(langSelect.value)"
            [value]="translate.currentLang"
          >
            <mat-select-trigger class="custom-align-icon">
              <span class="spacing">
                <mat-icon
                  svgIcon="{{ selectedLanguage.languageIcon }}"
                ></mat-icon>
              </span>
              {{ selectedLanguage.languageName }}
            </mat-select-trigger>
            <mat-option
              *ngFor="let lang of languages"
              [value]="lang.languageCode"
              [selected]="lang.languageCode === translate.currentLang"
            >
              <span>
                <mat-icon svgIcon="{{ lang.languageIcon }}"></mat-icon>
              </span>
              {{ lang.languageName }}
            </mat-option>
          </mat-select>
          <mat-label>{{ "LOGIN.LANGUAGE.CHOOSE" | translate }}</mat-label>
        </mat-form-field>
      </mat-card>
    </div>
  </mat-grid-list>
</div>
