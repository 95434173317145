import { Component, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert, UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AlertComponent } from '../alert/alert.component';
import { MatDialog } from '@angular/material/dialog';
import { TicketService } from 'src/app/services/ticket.service';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss'],
})
export class TicketDetailsComponent implements OnInit, OnDestroy {
  ticketInfo: any;
  prize: any;
  amount: any;
  ticketId = sessionStorage.getItem('ticketId');
  heading: string;
  headingSubscription: Subscription;
  serviceSubscription: Subscription;

  @ViewChild("scannedTicket", { static: false }) scannedTicket: ElementRef;
  uploadedDoc: any;
  buttonState: string = "mat-button-disabled";
  ticketUploaded: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private dialog: MatDialog,
    private ticketService: TicketService  ) {
  }

  ngOnInit(): void {
    this.displayTicketInfo();
    this.headingSubscription = this.translate
      .get('HEADER', { value: 'TITLE_STAGE_1' })
      .subscribe((res: { TITLE_STAGE_2: string; TITLE_STAGE_1: string }) => {
        this.heading = res.TITLE_STAGE_1;
      });
  }

  ngOnDestroy() {
    if (this.headingSubscription) this.headingSubscription.unsubscribe();
    if (this.serviceSubscription) this.serviceSubscription.unsubscribe();
  }

  public displayTicketInfo(): any {
    this.ticketInfo = this.activatedRoute.snapshot.data.data;
    this.prize = UtilService.getNorwegianCurrencyFormat(
      this.activatedRoute.snapshot.data.data.prize
    );
    this.amount = UtilService.getNorwegianCurrencyFormat(
      this.activatedRoute.snapshot.data.data.amount
    );
  }

  public nextPage(): void {
    this.router.navigate(['/register/' + Number(this.ticketId)]);
  }

  public openDialog(alert: Alert): any {
    this.dialog.open(AlertComponent, {
      data: alert,
      panelClass: 'custom-alert',
    });
  }

  public validateAndUploadedDoc($event): void {
    if ($event.target.files.length > 0) {
      const formData = new FormData();
      formData.append('ticket', $event.target.files[0]);
      formData.append('ticketId', this.ticketId);
      formData.append('securityCode', sessionStorage.getItem('pwd'));

      this.serviceSubscription = this.ticketService.uploadId(formData)
        .subscribe(
          (event: any) => {
            this.buttonState = "primary-btn";
            this.ticketUploaded = true;
          },
          (err) => {
            this.openDialog(
              UtilService.alertMessage(
                this.translate.instant(
                  'TICKET_DETAILS.VALIDATION.FILE_UPLOAD_ERROR_MESSAGE'
                ),
                0
              )
            );
          }
        );
    }
  }
}
