import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PrizedTicketService {

  constructor(private http: HttpClient) {}

  public retrievePrizedTickets(page: number): Observable<Array<number>> {
    var url = environment.baseUrl + '/ticket-verification/prized-ticket/' + page;
    return this.http.get<Array<number>>(url);
  }
}
