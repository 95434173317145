import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileConverterService {

  constructor(private http: HttpClient) { }

  public convert(formData: any): Observable<any> {
    const headers = {
      // headers: new HttpHeaders().set('Accept', 'application/json'),
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(environment.baseUrl + '/ticket-verification/scan-convertor', formData, headers);
  }
}
