import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor} from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { HttpStateService } from '../services/http-state.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private httpStateService: HttpStateService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      const spinnerSubscription: Subscription = this.httpStateService.init.subscribe();
      return next.handle(request).pipe(finalize(() => spinnerSubscription.unsubscribe()));
    }
}
