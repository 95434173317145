import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TicketService } from '../services/ticket.service';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationGuard implements Resolve<any> {

  constructor(private ticketService: TicketService) {}

  resolve(): any {
    const ticketId = sessionStorage.getItem('ticketId');
    const securityCode = sessionStorage.getItem('pwd');
    
    return this.ticketService.getTicketVerificationDetails(Number(ticketId), securityCode);
  }

}
