<div class="view-prized-ticket-main">
  <mat-grid-list cols="12">
    <mat-card class="view-prized-ticket-table logo-container">
      <div class="logo-form">
        <img src="../../../assets/images/logo_login.png" class="logo" />
      </div>
    </mat-card>

    <div class="view-prized-ticket-table">
      <div class="view-prized-ticket-heading">
        <h1>
          {{ "VIEW_PRIZED_TICKETS.HEADING" | translate }}
        </h1>
        <h3>
          {{ "VIEW_PRIZED_TICKETS.SUB_HEADING" | translate }}
        </h3>
      </div>
      <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="col1">
          <mat-cell *matCellDef="let row"> {{row.col1}} </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="col2">
          <mat-cell *matCellDef="let row"> {{row.col2}} </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="col3">
          <mat-cell *matCellDef="let row"> {{row.col3}} </mat-cell>
        </ng-container>
      
        <mat-row *matRowDef="let row; columns: ['col1', 'col2', 'col3']"></mat-row>
      </mat-table>
      <div class="view-prized-ticket-heading">
        <mat-paginator #paginator
          [pageSizeOptions]="pageSizeOptions"
          showFirstLastButtons
          (page)="handlePageEvent($event)"
          aria-label="Select page"
        ></mat-paginator>
    </div>
    </div>
  </mat-grid-list>
</div>
