import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Alert, UtilService } from 'src/app/services/util.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../alert/alert.component';
import { TranslateService } from '@ngx-translate/core';
import { skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'app-tc-login',
  templateUrl: './tc.login.component.html',
  styleUrls: ['./tc.login.component.scss'],
})
export class TicketCheckLoginComponent implements OnInit {
  public languages;
  loginForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    public translate: TranslateService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      ticketId: [null, Validators.required],
      securityCode: [null, Validators.required],
    });
  }

  public openDialog(alert: Alert): any {
    this.dialog.open(AlertComponent, {
      data: alert,
      panelClass: 'custom-alert',
    });
  }

  public login(): void {
    this.loginForm
      .get('securityCode')
      .patchValue(this.loginForm.get('securityCode').value.toUpperCase());
    this.authService
      .login(this.loginForm.value)
      .pipe(skipWhile((x) => !x))
      .subscribe(
        (data) => {
          if (!!data) {
            this.authService.setStatus(true);
            this.router.navigate(['/ticket-check/', data?.ticketId]);
          } else {
            this.openDialog(
              UtilService.alertMessage(
                this.translate.instant('LOGIN.MESSAGE_NOT_FOUND'),
                0
              )
            );
          }
        },
        (err) => {
          this.openDialog(
            UtilService.alertMessage(
              this.translate.instant('LOGIN.CONNECTION_FAILURE'),
              0
            )
          );
        }
      );
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  };

  public isNumberKey(event: KeyboardEvent): boolean {
    return this.utilService.isNumberKey(event);
  }
}
