import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Alert, UtilService } from 'src/app/services/util.service';
import { Language, LanguageService } from 'src/app/services/language.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../alert/alert.component';
import { TranslateService } from '@ngx-translate/core';
import { skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public languages;
  public selectedLanguage: Language;
  loginForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    public translate: TranslateService,
    private languageService: LanguageService,
    private utilService: UtilService
  ) {
    this.selectedLanguage = this.languageService.getDefaultLang();
  }

  ngOnInit(): void {
    this.languages = this.languageService.getLanguages();
    this.loginForm = this.formBuilder.group({
      ticketId: [null, Validators.required],
      securityCode: [null, Validators.required],
    });
  }

  public openDialog(alert: Alert): any {
    this.dialog.open(AlertComponent, {
      data: alert,
      panelClass: 'custom-alert',
    });
  }

  public login(): void {
    this.loginForm
      .get('securityCode')
      .patchValue(this.loginForm.get('securityCode').value.toUpperCase());
    this.authService
      .login(this.loginForm.value)
      .pipe(skipWhile((x) => !x))
      .subscribe(
        (data) => {
          if (!!data) {
            sessionStorage.setItem('ticketId', JSON.stringify(data?.ticketId));
            sessionStorage.setItem('pwd', data?.pwd);
            this.redirectByWorkflowStatus(data?.ticketWorkflowStatusId);
          } else {
            this.openDialog(
              UtilService.alertMessage(
                this.translate.instant('LOGIN.MESSAGE_NOT_FOUND'),
                0
              )
            );
          }
        },
        (err) => {
          this.openDialog(
            UtilService.alertMessage(
              this.translate.instant('LOGIN.CONNECTION_FAILURE'),
              0
            )
          );
        }
      );
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  };

  private redirectByWorkflowStatus(ticketWorkflowStatusId: number): any {
    const ticketId = this.loginForm.value.ticketId;
    switch (ticketWorkflowStatusId) {
      case 1:
      case 3:
        this.authService.setStatus(true);
        this.router.navigate(['/process']);
        break;
      case 2:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 13:
        this.openDialog(
          UtilService.alertMessage(
            this.translate.instant('LOGIN.MESSAGE_ALREADY_REGISTERED'),
            0
          )
        );
        break;
      case 11:
        this.openDialog(
          UtilService.alertMessage(
            this.translate.instant('LOGIN.MESSAGE_EXPIRED'),
            0
          )
        );
        break;
      default:
        this.openDialog(
          UtilService.alertMessage(
            this.translate.instant('LOGIN.MESSAGE_NOT_FOUND'),
            0
          )
        );
        break;
    }
  }
    
  public switchLanguage($event): any {
    this.selectedLanguage = this.languageService.switchLanguage($event);
    this.translate.use(this.selectedLanguage.languageCode);
    this.languageService.setLanguageState$($event);
  }

  public isNumberKey(event: KeyboardEvent): boolean {
    return this.utilService.isNumberKey(event);
  }
}
