import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { RegisterTicketComponent } from './components/register-ticket/register-ticket.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth-guard';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { TicketDetailsComponent } from './components/ticket-details/ticket-details.component';
import { ResolveGuard } from './guards/resolve.guard';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './components/landing/landing.component';
import { ViewPrizedTicketComponent } from './components/view-prized-ticket/view.prized.ticket.component';
import { TicketCheckLoginComponent } from './components/tc-login/tc.login.component';
import { TicketCheckComponent } from './components/ticket-check/ticket.check.component';
import { ClaimProcessComponent } from './components/claim-process/claim.process.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ConfirmationGuard } from './guards/confirmation.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/' },
  { path: '', component: LandingComponent },
  { path: 'login', component: LoginComponent },
  { path: 'process', component: ClaimProcessComponent },
  { path: 'view-prized-ticket', component: ViewPrizedTicketComponent },
  { path: 'tc-login', component: TicketCheckLoginComponent },
  { path: 'ticket-details', component: TicketDetailsComponent, canActivate: [AuthGuard],
    resolve: {
      data: ResolveGuard
  }},
  { path: 'register/:id', component: RegisterTicketComponent, canActivate: [AuthGuard] },
  { path: 'thank-you', component: ThankYouComponent},
  { path: 'ticket-check/:ticketId', component: TicketCheckComponent, canActivate: [AuthGuard] },
  { path: 'confirmation', component: ConfirmationComponent,
    canActivate: [AuthGuard],
    resolve: { confirmation: ConfirmationGuard }
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
  CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
