import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from '../components/login/login.component';
import { RegisterTicketComponent } from '../components/register-ticket/register-ticket.component';
import { AngularMaterialModule } from './angular-material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from '../components/header/header.component';
import { TicketDetailsComponent } from '../components/ticket-details/ticket-details.component';
import { ThankYouComponent } from '../components/thank-you/thank-you.component';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { AlertComponent } from '../components/alert/alert.component';
import { LandingComponent } from '../components/landing/landing.component';
import { ViewPrizedTicketComponent } from '../components/view-prized-ticket/view.prized.ticket.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TicketCheckLoginComponent } from '../components/tc-login/tc.login.component';
import { TicketCheckComponent } from '../components/ticket-check/ticket.check.component';
import { ClaimProcessComponent } from '../components/claim-process/claim.process.component';
import { ConfirmationComponent } from '../components/confirmation/confirmation.component';

const components = [
  ConfirmationComponent,
    TicketCheckLoginComponent, TicketCheckComponent,
    ViewPrizedTicketComponent, LandingComponent, LoginComponent,
    ClaimProcessComponent, RegisterTicketComponent,
    HeaderComponent, TicketDetailsComponent,
    ThankYouComponent, HeaderComponent,
    AlertComponent, SpinnerComponent
];

@NgModule({
  declarations: [ ...components ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    MatTableModule,
    MatPaginatorModule

  ],
  exports: [ ...components ],
})
export class AppLocalModule {
  constructor() {}
}
