// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /* local develop */
  baseUrl: 'http://localhost:8080/frontend',

  /* remote develop */
  //baseUrl: 'http://gevinst-test.pantelotteriet.net/frontend',

  /* Production */
  // baseUrl: 'http://admin.pfandlotterie.de/frontend',
  // gaKey: 'UA-8823408-6',
  defaultLanguage: 'de',
  countryCode: 'de-DE',
  currency: 'EUR',
  video: 'https://www.youtube.com/watch?v=h1Cc_ioF2E0',
  minLimit: 100000
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
