import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MaterialFileInputModule} from 'ngx-material-file-input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
{}
@NgModule({
    imports: [
       CommonModule,
       MatButtonModule,
       MatCardModule,
       MatToolbarModule,
       MatIconModule,
       MatFormFieldModule,
       MatInputModule,
       MaterialFileInputModule,
       MatDialogModule,
       MatGridListModule,
       MatListModule,
       MatSelectModule,
       MatProgressSpinnerModule,
    ],
    exports: [
       MatButtonModule,
       MatIconModule,
       MatToolbarModule,
       MatInputModule,
       MatFormFieldModule,
       MatCardModule,
       MaterialFileInputModule,
       MatDialogModule,
       MatGridListModule,
       MatListModule,
       MatSelectModule,
       MatProgressSpinnerModule,
    ],
    providers: [
       MatDatepickerModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
 })
export class AngularMaterialModule { }
