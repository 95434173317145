import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Sanitizer } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppLocalModule } from './modules/app-local.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './guards/auth-guard';
import { ResolveGuard } from './guards/resolve.guard';
import { ConfirmationGuard } from './guards/confirmation.guard';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularMaterialModule } from './modules/angular-material.module';
import { HttpConfigInterceptor } from './interceptor/http-config.interceptor';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SanitizehtmlPipe } from './pipes/sanitizehtml.pipe';
import { AppRoutingModule } from './app-routing.module';

export function translationHttpLoaderFactory(http: HttpClient): any {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const BROWSER_SANITIZATION_PROVIDERS: Array<any> = [
  {provide: Sanitizer, useExisting: DomSanitizer}
];

@NgModule({
  declarations: [
    AppComponent,
    SanitizehtmlPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppLocalModule,
    AngularMaterialModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translationHttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    AuthGuard, ResolveGuard, ConfirmationGuard,
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    BROWSER_SANITIZATION_PROVIDERS
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
