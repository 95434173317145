import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AlertComponent } from '../components/alert/alert.component';

declare var gtag;

export interface Alert {
    message: string;
    type: number;
}

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    static dialog: any;

    constructor(private dialog: MatDialog) { }

    private static convertToCents(value: number): number {
        return value / 100;
    }

    public static getNorwegianCurrencyFormat(value: number): string {
        const norwegianCurrencyFormat = new Intl.NumberFormat(environment.countryCode,
            { style: 'currency', currency: environment.currency });
        return `${norwegianCurrencyFormat.format(this.convertToCents(value))}`;
    }

    public static validateAccountNumber(countryCode: string, accountNo: string): boolean {
        accountNo = accountNo.replace(' ', '');
      if (countryCode.search('DE') >= 0) {
        var result = this.evaluateIbanRules(accountNo.toUpperCase(), "DE");
        return result;
      } else if (countryCode.search('NO') >= 0) {
        return this.evaluateNorwegianRules(accountNo);
      }

      return false;
    }

  private static evaluateIbanRules(accountNo: string, countryPrefix: string) {
      return accountNo.search("^" + countryPrefix + "[0-9]{18,20}$") >= 0;
  }

  private static evaluateNorwegianRules(accountNo: string) {
    if (accountNo.toString().length > 10) {
      let results;
      const lastDigit = accountNo.toString().substring(accountNo.toString().length - 1);
      let sum = 0;
      ({ sum, results } = UtilService.checkAccountDigits(sum, accountNo, results));
      if (Number(results) === Number(lastDigit) && Number(accountNo.toString().length) === 11) {
        return true;
      }
    }
    return false;
  }


    private static checkAccountDigits(sum: number, accountNo: string, results: any): any {
        for (let index = 0; index < 10; index++) {
            sum += Number(accountNo.toString().charAt(index)) * (7 - (index + 2) % 6);
        }
        sum = (sum % 11);
        switch (sum) {
            case 0:
                results = 0;
                break;
            default:
                results = (11 - sum);
                break;
        }
        return { sum, results };
    }

    public static alertMessage(message: string, type: number): Alert {
        return {
            message,
            type
        };
    }

    public static openDialog(alert: Alert): any {
        this.dialog.open(AlertComponent, {
            data: alert,
            width: '30%'
        });
    }

    public isNumberKey(event: KeyboardEvent): boolean {
        // tslint:disable-next-line: deprecation
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        if (event.key === 'e' || event.key === '+' || event.key === '.') {
            event.preventDefault();
        }
        return true;
    }

    public isLegalCharacter(event: KeyboardEvent): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (
            (charCode > 32 && charCode < 39) ||
            (charCode > 59 || charCode < 64) ||
            (charCode > 90 || charCode < 95) ||
            (charCode > 122 || charCode < 126)
        ) {
            event.preventDefault();
        }
        
        return true;
    }
}
