import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, map, take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private isAuthenticated = false;
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> |
    Promise<boolean | UrlTree> | boolean | UrlTree {
        this.isAuth();
        if (!this.isAuthenticated) {
            this.authService.logout();
            this.router.navigate(['/']);
            return false;
        }
        return this.isAuthenticated;
  }

  private isAuth(): void {
    this.authService.getStatus().pipe(tap()).subscribe(data => {
        this.isAuthenticated = data;
    });
  }
}
