import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject } from 'rxjs';

export interface Language {
    languageCode: string;
    languageIcon: string;
    languageName: string;
}
@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    public lang$ = new ReplaySubject<string>();
    public languageData = [
        { languageCode: 'en', languageIcon: 'uk-flag', languageName: 'English' },
        // { languageCode: 'no', languageIcon: 'norway-flag', languageName: 'Norwegian -> Norsk' },
        { languageCode: 'de', languageIcon: 'german-flag', languageName: 'German' }
    ];

    constructor(private translate: TranslateService) {

    }

    public switchLanguage($event): Language {
        let selectedLang: Language;
        selectedLang = { languageCode: '', languageIcon: '', languageName: '' };
        for (const lang of this.languageData) {
            if (lang.languageCode === $event) {
                this.setLanguageState$(lang.languageCode);
                this.translate.use($event);
                selectedLang = lang;
                break;
            }
        }
        return selectedLang;
    }

    public setLanguageState$(lang: string): void {
        this.lang$.next(lang);
    }
    public getLanguageState$(): Observable<string> {
        return this.lang$;
    }

    public getDefaultLang(): Language {
        let selectedLang: Language;
        this.getLanguageState$().subscribe(response => {
            for (const lang of this.languageData) {
                if (lang.languageCode === response) {
                    this.translate.use(response);
                    selectedLang = lang;
                }
            }
        });
        return selectedLang;
    }

    public getLanguages(): Array<Language> {
        return this.languageData;
    }
}
