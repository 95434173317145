import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export interface ReceiptTicket {
  ticketId: number;
  amount: number;
  prize: string;
}
export interface Receipt {
  receiptId: number;
  tickets: ReceiptTicket[];
}

@Injectable({
  providedIn: 'root',
})
export class TicketCheckService {
  constructor(private http: HttpClient) {}

  public checkTicket(ticketId: number): Observable<Receipt> {
    return this.http
      .get<Receipt>(
        environment.baseUrl +
          '/ticket-verification?ticketId=' + ticketId
      );
  }
}
