import { Component, AfterViewChecked, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Language, LanguageService } from './services/language.service';
declare var gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  public selectedLanguage: Language;
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer, private router: Router,
              private translate: TranslateService, private languageService: LanguageService) {
      const browserLang = this.translate.getBrowserLang();
      this.translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
  }

  ngOnInit(): void {
    this.iconRegistry.addSvgIcon('uk-flag', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/uk-flag.svg'));
    // this.iconRegistry.addSvgIcon('norway-flag', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/norway-flag.svg'));
    this.iconRegistry.addSvgIcon('german-flag', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/german-flag.svg'));

    const navEndEvents = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
    navEndEvents.subscribe((event: NavigationEnd) => {
      // gtag('config', environment.gaKey, {
      //     page_path: event.urlAfterRedirects
      // });
    });
    this.languageService.setLanguageState$(environment.defaultLanguage);
    this.languageService.getDefaultLang();
  }
}
