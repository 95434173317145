import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Alert } from 'src/app/services/util.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../alert/alert.component';
import { TranslateService } from '@ngx-translate/core';
import { Language, LanguageService } from '../../services/language.service';
import { PrizedTicketService } from '../../services/prized.ticket.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';


export interface ColumnElement {
  col1: string;
  col2: string;
  col3: string;
}

export interface PaginationElement {
  previousPageIndex: number,
  pageIndex: number,
  pageSize: number,
  length: number
}

@Component({
  selector: 'app-view-prized-ticket',
  templateUrl: './view.prized.ticket.component.html',
  styleUrls: ['./view.prized.ticket.component.scss'],
})
export class ViewPrizedTicketComponent implements OnInit, OnDestroy {
  public languages;
  public selectedLanguage: Language;
  public paginatedTickets = new Array<ColumnElement>();
  public currentPageIndex = 0;
  public currentPage = 0;
  public pageSizeOptions: number[] = [5, 10, 15];

  public dataSource: MatTableDataSource<ColumnElement>;
  private subscription: Subscription;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public translate: TranslateService,
    private languageService: LanguageService,
    private prizedTicketService: PrizedTicketService,
  ) {
    this.selectedLanguage = this.languageService.getDefaultLang();
  }

  public switchLanguage($event): any {
    this.selectedLanguage = this.languageService.switchLanguage($event);
    this.translate.use(this.selectedLanguage.languageCode);
    this.languageService.setLanguageState$($event);
  }

  ngOnInit(): void {
    this.languages = this.languageService.getLanguages();
    this.loadPrizeTicketBatch(0);
  }

  ngOnDestroy(): void {
    if (!!this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public openDialog(alert: Alert): any {
    this.dialog.open(AlertComponent, {
      data: alert,
      panelClass: 'custom-alert',
    });
  }

  public handlePageEvent(event: PaginationElement): void {
    let calculatedPageIndex = Math.floor(Math.round(event.length / event.pageSize));
    let page = false;
    if (event.pageIndex == calculatedPageIndex) {
      page = true;
    } else {
      page = Math.abs(calculatedPageIndex - event.pageIndex) == 1;
    }

    this.currentPage++;
    this.loadPrizeTicketBatch(this.currentPage);
}

  loadPrizeTicketBatch(page: number): void {
    const temporary = new Array<ColumnElement>();

    this.subscription = this.prizedTicketService.retrievePrizedTickets(page).subscribe(
      (response) => {
        if (!!response) {
          let index = 0;
          let elementValues = [];
          response.forEach(value => {
              elementValues.push(value);
              index++;
              if (index === 2) {
                const element:ColumnElement = {
                  col1: (elementValues[0]).toString(),
                  col2: (elementValues[1]).toString(),
                  col3: (value).toString()
                };

                if (page == 0) {
                  temporary.push(element); 
                } else {
                  this.dataSource.data.push(element);
                }
                elementValues = [];
                index = 0;
              }
          });

          if (page == 0) {
            this.paginatedTickets = temporary;
            this.dataSource = new MatTableDataSource<ColumnElement>(this.paginatedTickets);
            this.dataSource.paginator = this.paginator;
          } else {
            this.table.renderRows();
          }
        }
      }
    );
  }
}
