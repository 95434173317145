import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Alert } from 'src/app/services/util.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../alert/alert.component';
import { TicketService, ConfirmationDetails } from 'src/app/services/ticket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  confirmation: ConfirmationDetails;
  state: any;
  securityCode: any;
  confirmServiceSubscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private ticketService: TicketService
  ) { }

  ngOnInit(): void {
    this.state = window.history.state;
    this.confirmation = this.activatedRoute.snapshot.data.confirmation;
  }

  ngOnDestroy(): void {
    this.confirmServiceSubscription.unsubscribe();
  }

  public openDialog(alert: Alert): any {
    this.dialog.open(AlertComponent, {
      data: alert,
      panelClass: 'custom-alert',
    });
  }

  public confirm(): void {
    this.confirmServiceSubscription = this.ticketService.customerConfirmation(this.state.ticketId, this.state.securityCode)
    .subscribe((response) => {
      this.router.navigate(['/thank-you/']);  
    });
  }
}
