<div class="confirmation">
    <mat-grid-list cols="12">
        <mat-card class="logo-container">
            <div class="logo-form">
                <img src="../../../assets/images/logo_login.png" class="logo" />
            </div>
        </mat-card>

        <mat-card class="confirmation-details">
            <mat-grid-list cols="12" class="section heading">
                <h3 class="mat-card-title">
                    {{ "VERIFIED_TICKET.HEADING" | translate }}
                </h3>
                <h5 class="mat-card-subtitle">
                    {{ "VERIFIED_TICKET.SUB_HEADING" | translate }}
                </h5>
            </mat-grid-list>

            <mat-grid-list cols="12" class="section customer-details">
                <h4 class="header">
                    {{ "VERIFIED_TICKET.CUSTOMER.HEADING" | translate }}
                </h4>
                <div class="section-body">
                    <div class="name">{{ confirmation.customer.name }}</div>
                    <div class="cell">{{ confirmation.customer.cell }}</div>
                    <div class="address">{{ confirmation.customer.address }}</div>
                    <div class="zip">{{ confirmation.customer.zip }}</div>
                    <div class="city">{{ confirmation.customer.city }}</div>
                    <div class="email">{{ confirmation.customer.email }}</div>
                </div>
            </mat-grid-list>

            <mat-grid-list cols="12" class="section account-details">
                <h4 class="header">
                    {{ "VERIFIED_TICKET.CUSTOMER.ACCOUNT.HEADING" | translate }}
                </h4>
                <div class="section-body">
                    <div class="email">{{ confirmation.customer.accountNumber }}</div>
                </div>
            </mat-grid-list>

            <mat-grid-list cols="12" class="section docs-details">
                <h4 class="header">
                    {{ "VERIFIED_TICKET.TICKET.HEADING" | translate }}
                </h4>
                <ul class="section-body">
                    <li>
                        <img mat-card-image src="../../../assets/images/Uploaded.svg" />
                        <span class="doc-name">
                            {{ "VERIFIED_TICKET.TICKET.TICKET_IMAGE_ID" | translate }}
                        </span>
                    </li>
                    <li>
                        <img mat-card-image src="../../../assets/images/Uploaded.svg" />
                        <span class="doc-name">
                            {{ "VERIFIED_TICKET.TICKET.CUSTOMER_IMAGE_ID" | translate }}
                        </span>
                    </li>
                </ul>
            </mat-grid-list>
            <div class="upload-docs">
                <span for="confirmBtn" class="upload-docs" (mouseup)="confirm()">
                    {{ "REGISTER_TICKET.FORM.TICKET_SCAN.SCANNED_TICKET_PLACEHOLDER" | translate }}
                    <button
                            mat-raised-button  class="upload-docs"
                            #confirmBtn id="confirmBtn" name="confirmBtn"
                            (click)="confirm()">
                        {{ "REGISTER_TICKET.FORM.SUBMIT" | translate }}
                    </button>
                    <img alt="ID Document upload" src="../../../assets/images/IDUpload.svg" />
                </span>
            </div>
        </mat-card>
    </mat-grid-list>
</div>