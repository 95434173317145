<div class="page-container">
  <mat-grid-list cols="12">
    <mat-card class="landing-instructions logo-container">
      <div class="logo-form">
        <img src="../../../assets/images/logo_login.png" class="logo" />
      </div>
    </mat-card>
  </mat-grid-list>
  
  <div class="ticket-details">
    <mat-card class="register_card" flex-gt-s>
      <mat-card-title class="verification-title">
        {{ "TICKET_DETAILS.TITLE" | translate }}
      </mat-card-title>

      <mat-card-subtitle class="verification-title">
        {{ "TICKET_DETAILS.SUBTITLE_BODY" | translate }}
      </mat-card-subtitle>

      <mat-divider></mat-divider>

      <mat-list>
        <div class="row-data ticket-id">
          <div mat-subheader>{{ "TICKET_DETAILS.RECEIPT_NUMBER" | translate }}</div>
          <mat-list-item>
            <div mat-line>{{ ticketInfo?.ticketId }}</div>
          </mat-list-item>
        </div>

        <mat-divider matInset></mat-divider>

        <div class="row-data security-code">
          <div mat-subheader>{{ "TICKET_DETAILS.SECURITY_NUMBER" | translate }}</div>
          <mat-list-item>
            <div mat-line>{{ ticketInfo?.securityCode }}</div>
          </mat-list-item>
        </div>

        <mat-divider matInset></mat-divider>

        <div class="row-data shop">
          <div mat-subheader>{{ "TICKET_DETAILS.SHOP_NAME" | translate }}</div>
          <mat-list-item>
            <div mat-line>{{ ticketInfo?.shop }}</div>
          </mat-list-item>
        </div>

        <mat-divider matInset></mat-divider>

        <div class="row-data prize">
          <div mat-subheader>{{ "TICKET_DETAILS.PRIZE" | translate }}</div>
          <mat-list-item>
            <h4 matLine>{{ prize }}</h4>
          </mat-list-item>
        </div>

        <mat-divider matInset></mat-divider>

        <div class="row-data amount">
          <div mat-subheader>{{ "TICKET_DETAILS.AMOUNT" | translate }}</div>
          <mat-list-item>
            <h4 matLine>{{ amount }}</h4>
          </mat-list-item>
        </div>

        <mat-divider matInset></mat-divider>

        <div class="row-data sdate">
          <div mat-subheader>{{ "TICKET_DETAILS.SDATE" | translate }}</div>
          <mat-list-item>
            <h4 matLine>{{ ticketInfo?.sdate | date: "dd.LL.yyyy HH:mm:ss" }}</h4>
          </mat-list-item>
        </div>

        <mat-divider matInset></mat-divider>

        <div class="row-data docs-text">
          <div mat-subheader>{{ "TICKET_DETAILS.DOC_UPLOAD" | translate }}</div>
          <mat-list-item>
            <div class="mat-card-subtitle">{{"TICKET_DETAILS.DOC_UPLOAD_TEXT" | translate }}</div>
          </mat-list-item>
        </div>

        <mat-divider matInset></mat-divider>

        <div class="row-data doc-requirements-text">
          <div mat-subheader>{{ "TICKET_DETAILS.REQUIRED_DOCS" | translate }}</div>
          <div class="mat-card-subtitle">
            <ul style="list-style-type:disc">
              <li>{{"TICKET_DETAILS.REQUIRED_DOCS_TEXT" | translate }}</li>
            </ul>
          </div>
        </div>

        <mat-divider></mat-divider>
        <div class="upload-docs">
          <span for="scannedTicket" class="upload-docs">
            Klicken Sie hier, um das Ticket hochzuladen
            <input mat-raised-button #ticketDocs type="file" class="upload-docs" id="scannedTicket" name="scannedTicket"
              placeholder="{{ 'TICKET_DETAILS.BUTTON_UPLOAD' | translate}}" (change)="validateAndUploadedDoc($event)"
              accept="image/jpg,image/jpeg,image/png,image/gif,application/pdf" />
            <img alt="Ticket upload" src="../../../assets/images/TicketUpload.svg" />
          </span>
        </div>
        <mat-card-actions buttons>
          <div class="next-button">
            <button mat-raised-button (click)="nextPage()" class="{{ buttonState }}" [disabled]="!ticketUploaded">
              {{ "TICKET_DETAILS.BUTTON" | translate }}
            </button>
          </div>
        </mat-card-actions>
      </mat-list>
    </mat-card>
  </div>
</div>