<div class="claim-process">
    <mat-grid-list cols="12">
      <mat-card class="logo-container">
          <div class="logo-top">
              <img src="../../../assets/images/logo_login.png"/>
          </div>
      </mat-card>
        <mat-card-content>
            <mat-card class="claim_process_card">
                <h2>{{'CLAIM_NOTICE.TITLE' | translate}}</h2>
                <p>
                    <span class="bolder">{{'CLAIM_NOTICE.START' | translate}}</span>
                    {{'CLAIM_NOTICE.PHRASE1' | translate}}
                    <span class="bolder">{{'CLAIM_NOTICE.ID' | translate}}</span>
                    &nbsp;{{'CLAIM_NOTICE.PHRASE2' | translate}}
                </p>
                <p>{{'CLAIM_NOTICE.PHRASE3' | translate}}</p>
                <div class="card-footer">
                    <div class="left">
                        <p class="thanks">{{'CLAIM_NOTICE.THANKS' | translate}}</p>
                        <button (click)="goTo()" mat-raised-button class="primary-btn">{{'CLAIM_NOTICE.CONTINUE' | translate}}</button>
                    </div>
                    <div class="right">
                        <img src="../../../assets/images/congrats.svg" />
                    </div>
                </div>
            </mat-card>
        </mat-card-content>

        <div>
          <mat-card class="landing-lang">
            <mat-form-field class="input-form-size" appearance="outline">
              <mat-select
                #langSelect
                (selectionChange)="switchLanguage(langSelect.value)"
                [value]="translate.currentLang"
              >
                <mat-select-trigger class="custom-align-icon">
                  <span class="spacing">
                    <mat-icon
                      svgIcon="{{ selectedLanguage.languageIcon }}"
                    ></mat-icon>
                  </span>
                  {{ selectedLanguage.languageName }}
                </mat-select-trigger>
                <mat-option
                  *ngFor="let lang of languages"
                  [value]="lang.languageCode"
                  [selected]="lang.languageCode === translate.currentLang"
                >
                  <span>
                    <mat-icon svgIcon="{{ lang.languageIcon }}"></mat-icon>
                  </span>
                  {{ lang.languageName }}
                </mat-option>
              </mat-select>
              <mat-label>{{ "LOGIN.LANGUAGE.CHOOSE" | translate }}</mat-label>
            </mat-form-field>
          </mat-card>
        </div>
    </mat-grid-list>
</div>
