import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit  {

  message = '';
  class = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AlertComponent>) {
    if (data) {
        this.message = data.message || this.message;
    }
    this.dialogRef.updateSize('200px', '300px');
  }

  ngOnInit(): void {
      if (this.data.type === 1) {
          this.class = 'accent';
      } else if (this.data.type === 0) {
        this.class = 'primary';
      }
  }

  public onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
