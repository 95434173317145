import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Alert } from 'src/app/services/util.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../alert/alert.component';
import { TranslateService } from '@ngx-translate/core';
import { Language, LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  public languages;
  public selectedLanguage: Language;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public translate: TranslateService,
    private languageService: LanguageService,
  ) {
    this.selectedLanguage = this.languageService.getDefaultLang();
  }

  public switchLanguage($event): any {
    this.selectedLanguage = this.languageService.switchLanguage($event);
    this.translate.use(this.selectedLanguage.languageCode);
    this.languageService.setLanguageState$($event);
  }

  ngOnInit(): void {
    this.languages = this.languageService.getLanguages();
  }

  public openDialog(alert: Alert): any {
    this.dialog.open(AlertComponent, {
      data: alert,
      panelClass: 'custom-alert',
    });
  }

  public showPrizedTicket(): void {
    this.router.navigate(['/view-prized-ticket']);
  }

  public showClaimTicket(): void {
    this.router.navigate(['/login']);
  }

  public checkTicket(): void {
    this.router.navigate(['/tc-login']);
  }
}
